<template>
  <div>
    <tw-header :title="$t('BreadCrumbs.Trading List')" :hasBottom="true">
      <template v-slot:column>
        <tw-button
          v-if="isShipper && isPic && !forbidden"
          type="secondary"
          size="small"
          icon="plus"
          style="margin-left: 24px"
          @click="addTrading"
        >
          Trading
        </tw-button>
      </template>
      <template v-slot:bottom>
        <div class="trading_tabs">
          <div class="tab list" :class="{active: activeTab === 0, disabled: !(isShipper && !forbidden)}" @click="changeTab(0)"><i/>List</div>
          <div class="tab flow" :class="{active: activeTab === 1, disabled: !(isShipper && !forbidden)}" @click="changeTab(1)"><i/>Flow</div>
        </div>
      </template>
    </tw-header>
    <div class="tw_container">
      <tw-breadcrumbs :pages="breadcrumbs" style="margin-bottom: 40px" />
      <tw-entity-buttons />

      <el-form v-if="isShipper && !forbidden" class="search_unit compact" ref="searchUnit" :model="searchForm" :rules="rules" label-position="top">
        <div class="row" style="border-bottom: 1px solid #BFBFCD;margin-bottom: 12px;">
          <el-form-item class='max-content' :label="$t('Label.Update Datetime')" prop='flowUpdateDatetime'>
            <el-date-picker
              popper-class="within-system-trading-list-days"
              v-model='searchForm.flowUpdateDatetime'
              type='datetimerange'
              range-separator='to'
              format='yyyy-MM-dd HH:mm'
              value-format='yyyy-MM-dd HH:mm:ss'
              :default-time="['00:00:00', '23:59:59']"
              start-placeholder='Start datetime'
              end-placeholder='End datetime'
              :pickerOptions="{
                onPick: pickUpdateDate,
                disabledDate: disabledUpdateDate,
                shortcuts
              }"
              :clearable="false"
              @blur="setUpdateDateInfo('reset')">
            </el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('Label.Owner')" prop="ownerSectionIds">
            <el-select v-model="searchForm.ownerSectionIds" class="section" :class="{multiple_selected: multipleSelect(searchForm.ownerSectionIds)}" placeholder="Select" multiple collapse-tags clearable>
              <el-option
                v-for="section in ownerSectionLists"
                :key="section.sectionId"
                :label="section.sectionShortName"
                :value="section.sectionId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('Label.Section(From)')" prop="fromSectionIds">
            <TwSelectSection v-model="searchForm.fromSectionIds" :sections="fromSections" />
          </el-form-item>
          <el-form-item :label="$t('Label.Section(To)')" prop="toSectionIds">
            <TwSelectSection v-model="searchForm.toSectionIds" :sections="toSections" />
          </el-form-item>
          <tw-button type="secondary" size="small" class="toggle_button" :class="{ open: openForm }" @click="openToggle">
            <img src="@/assets/images/icons/view_all.svg">
          </tw-button>
        </div>

        <transition
          name="content"
          @enter="enter"
          @after-enter="afterEnter"
          @leave="leave"
          @after-leave="afterLeave"
        >
          <div v-show="openForm">
            <div class="row">
              <el-form-item :label="$t('Label.Trading ID / Flow ID')" prop="tradingFlowId">
                <el-input type="text" v-model.trim="searchForm.tradingFlowId" placeholder="Enter keyword" class="trading-flow"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Trading Name')" prop="tradingName">
                <el-input type="text" v-model="searchForm.tradingName" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Flow Name')" prop="flowName">
                <el-input type="text" v-model="searchForm.flowName" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Invoice No')" prop="invoiceNo">
                <el-input type="text" v-model="searchForm.invoiceNo" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Contract Date')" prop="contractDate">
                <el-date-picker
                  v-model="searchForm.contractDate"
                  type="daterange"
                  range-separator="to"
                  format="yyyy-MM-dd"
                  :default-value="defaultDate"
                  @change.native="onDateRangeChange"
                  start-placeholder="Start date"
                  end-placeholder="End date">
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item :label="$t('Label.Seller')" prop="sellerName">
                <el-input type="text" v-model="searchForm.sellerName" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Buyer')" prop="buyerName">
                <el-input type="text" v-model="searchForm.buyerName" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Main Goods')" prop="goodsName">
                <el-input type="text" v-model="searchForm.goodsName" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <!-- <el-form-item :label="$t('Label.Status')" prop="tradingProgressStatus" style="margin-right: 40px">
                <el-select v-model="searchForm.tradingProgressStatus" placeholder="Select" clearable>
                  <el-option
                    v-for="status in PROCESS_PROGRESS_STATUS_VARIABLES"
                    :key="status.code"
                    :label="status.label"
                    :value="status.code">
                  </el-option>
                </el-select>
              </el-form-item> -->
              <div class='row'>
                <el-form-item class='max-content' :label="$t('Label.Create Datetime')" prop='createDatetime'>
                  <el-date-picker
                    v-model='searchForm.flowCreateDatetime'
                    type='datetimerange'
                    range-separator='to'
                    format='yyyy-MM-dd HH:mm'
                    value-format='yyyy-MM-dd HH:mm:ss'
                    :default-time="['00:00:00', '23:59:59']"
                    start-placeholder='Start datetime'
                    end-placeholder='End datetime'>
                  </el-date-picker>
                </el-form-item>
              </div>
            </div>
          </div>
        </transition>

        <div class="row drop space_between">
          <div>
            <tw-button
              type="secondary"
              size="medium"
              icon="search"
              :disabled="existValidationError"
              @click="search"
            >
              Search
            </tw-button>
            <tw-button
              type="default"
              size="medium"
              @click="clear"
              style="margin: 8px 16px 16px;"
            >
              Clear
            </tw-button>
          </div>

          <div class="right_buttons_wrapper">

            <el-dropdown trigger="click" placement="bottom" @command="onDropDownClick" :tabindex="-1">
              <tw-button class="menu_button" type="secondary" size="small" icon="menu">Menu</tw-button>
              <el-dropdown-menu class="nowrap">
                <el-dropdown-item command="tsv">{{$t('Label.TSV Download')}}</el-dropdown-item>
                <el-dropdown-item command="openTableSettingDialog">{{ $t('Label.Column Preference') }}</el-dropdown-item>
                <el-dropdown-item command="changeTableWidth">{{ tableMode === 'show' ? $t('Label.Column Width') : $t('Label.Complete Width Edit Mode') }}</el-dropdown-item>
                <el-dropdown-item command="resetTableSetting">{{ $t('Label.Reset Column') }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-form>

      <tw-table-system :schemas="showSchema" :items="items" :border="tableMode === 'changeWidth'" :pageSize="pageSize" :totalCount="totalCount" :offset="offset" :selection="selection" :serverPagination="true" :isInitialSearch="isInitialSearch" :downloadStatus="downloadStatus" @row-click="rowClick" @row-dbclick="rowDbClick" @paging="onPaging" @change-column-width="changeColumnWidth" />      

    </div>
    <tw-drawer id="add_trading" :close-confirm="true" @close="closeConfirm">
      <template slot>
        <tw-add-trading v-if="drawerShow" :history="prevData" />
      </template>
    </tw-drawer>
    <tw-table-column-setting-dialog
      :visible.sync="isOpenTableSettingDialog"
      :full-schemas="schemas"
      :show-schemas.sync="filteredSchemas"
      @close="updateColumnSetting"
    ></tw-table-column-setting-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { TRADING_SEARCH_SORT_TARGET,DEFAULT_PAGENATION, API_USE_CODE,EDITABLE_FLG, TRADING_REGISTRATION_STATUS, LANGUAGE_SETTING } from 'lib-tw-common';
import { $api } from '@/store/ApiClient';
import mixinEntityList from '@/utils/mixinEntityList.js';
import mixinTableSetting from '@/utils/mixinTableSetting.js';
import { collapseTransition } from '@/utils/nextFrame';
import { clearSearchForm, formatUtcDate } from '@/utils/searchUtil.js';
import TwDrawer from '@/components/organisms/TwDrawer';
import TwAddTrading from '@/components/templates/TwAddTrading';
import schemas from '@/dictionaries/tradingListSchema.json';
import TwTableColumnSettingDialog from '@/components/organisms/TwTableColumnSettingDialog.vue';
import dayjs from 'dayjs';

const TABS = [
  'ListTrading',
  'ListTradingFlow',
]

export default {
  name: 'TradingList',
  mixins: [mixinEntityList, mixinTableSetting, collapseTransition],
  props: {
    history: Object,
  },
  components: {
    TwDrawer,
    TwAddTrading,
    TwTableColumnSettingDialog
  },
  data() {
    return {
      shortcuts: [{
        text: 'Today',
        onClick(picker) {
          picker.$emit('pick',
          [ 
            dayjs().format('YYYY-MM-DD 00:00:00'),
            dayjs().format('YYYY-MM-DD 23:59:59')
          ]
          )
        }
      }],
      isOpenTableSettingDialog: false,
      activeTab: 0,
      schemas: schemas,
      // テーブル表示のレスポンスデータのコピー
      displayItems: null,
      // オリジナルのレスポンスデータ
      items: null,
      addTradingResponse: null,
      searchForm: {
        ownerSectionIds: [],
        fromSectionIds: [],
        toSectionIds: [],
        tradingFlowId: '',
        tradingName: '',
        flowName: '',
        invoiceNo: '',
        contractDate: '',
        sellerName: '',
        buyerName: '',
        goodsName: '',
        tradingProgressStatus: null,
        flowUpdateDatetime: [],
        flowCreateDatetime: [],
      },
      minFlowUpdateDatetime: null,
      limit: DEFAULT_PAGENATION.LIMIT,
      offset: 0,
      sort: null,
      count: 0,
      totalCount: 0,
      pageCount: 0,
      lastQuery: {},
      prevData: null,
      // 初期検索フラグをemptyMessageの出し分けに使う
      isInitialSearch: 0,
      forbidden: false, // 認可エラーフラグ
      tableId: 'tradingList',
      existValidationError: false
    };
  },
  computed: {
    ownerSectionLists() {
      return this.$store.getters.getOwnerSectionLists;
    },
    drawerShow() {
      return this.$store.state.drawerShow
    },
    isShipper() {
      // カンパニーロールが荷主か
      return this.$store.getters.isShipper;
    },
    breadcrumbs() {
      return [
        {label: this.$t('BreadCrumbs.Dashboard'), to: '/'},
        {label: this.$t('BreadCrumbs.Trading List')},
      ];
    },
    downloadStatus() {
      return this.$store.getters.getDownloadStatus('TRADING_LIST');
    },
    isPic () {
      return this.$store.state.userInformationV2?.picFlg;
    },
    selectableUpdateDateRange() {
      return this.$store.getters.getSystemMasterData('SYS_MST_TRIAL_PERIOD', 'TRADING_LIST')?.value || 180;
    },
    rules() {
      const message = this.isEngLish ? `Please input within ${this.selectableUpdateDateRange} days` : `${this.selectableUpdateDateRange}日間以内で入力してください`;
      return {
        flowUpdateDatetime: [
          {
            message,
            validator: (rule, value, callback) => {
              // ruleが1つのみのためexistValidationErrorの値もここで管理とする
              if(value?.length !== 2 || this.checkSystemMasterDaysOver()) {
                this.existValidationError = true;
                callback(new Error(rule.message));
              } else {
                this.existValidationError = false;
                callback();
              }
            },
            trigger: 'change',
          }
        ]
      };
    },
    isEngLish() {
      return this.$store.state.language === LANGUAGE_SETTING.ENGLISH;
    }
  },
  async created() {
    this.$store.commit('SET_TRADING_LIST_MODE', 0);

    if (this.history && this.isPic) {
      if (!_.isEmpty(this.history)) {
        this.prevData = this.history;
      }
      this.$store.commit('SET_DRAWER', true);
    } else {
      this.$store.commit('SET_DRAWER', false);
    }

    const queries = this.$store.getters.getQueries('TRADING_LIST');
    if (queries) {
      this.searchForm = queries;
    }
    this.searchForm.flowUpdateDatetime = [
      dayjs().add(-this.selectableUpdateDateRange + 1, 'day').format('YYYY-MM-DD 00:00:00'),
      dayjs().format('YYYY-MM-DD 23:59:59')
    ];

    this.fetch();
  },
  mounted() {
    this.createDivsForUpdateDate()
  },
  methods: {
    // POSTパラメータを生成します
    transformQueries() {
      const f = this.searchForm;
      const queries = {
        ownerSectionIds: f.ownerSectionIds,
        fromSectionIds: f.fromSectionIds,
        toSectionIds: f.toSectionIds,
        tradingId: this.getTradingFlowId(f.tradingFlowId)[0],
        tradingFlowId: this.getTradingFlowId(f.tradingFlowId)[1],
        tradingName: f.tradingName,
        flowName: f.flowName,
        invoiceNo: f.invoiceNo,
        contractDateStartDate: this.getYMDStartDate(f.contractDate),
        contractEndDate: this.getYMDEndDate(f.contractDate),
        goodsName: f.goodsName,
        sellerName: f.sellerName,
        buyerName: f.buyerName,
        // tradingProgressStatus: this.getProcessProgressStatus(f.tradingProgressStatus),
        flowCreateDateFrom: f.flowCreateDatetime ? formatUtcDate(f.flowCreateDatetime[0]) : null,
        flowCreateDateTo: f.flowCreateDatetime?.[1] ? formatUtcDate(f.flowCreateDatetime[1]).replace('00Z', '59Z') : null,
        flowUpdateDateFrom: f.flowUpdateDatetime ? formatUtcDate(f.flowUpdateDatetime[0]) : null,
        flowUpdateDateTo: f.flowUpdateDatetime?.[1] ? formatUtcDate(f.flowUpdateDatetime[1]).replace('00Z', '59Z') : null,
      };

      return {
        ...queries,
        initialFlag: this.initialFlag(queries),
        limit: this.limit,
        offset: this.offset,
        sort: this.sort,
        apiUseCode: API_USE_CODE.TRADING_LIST,
      }
    },
    fetch(queries) {
      this.cancelRequestSources.forEach(tag => {
        $api.cancelRequests(tag);
      });
      // bff_td_1 取引一覧検索BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/tradings/search',
          query: queries || this.transformQueries()
        },
        tag: this.pushCancelTag(),
      };

      this.lastQuery = _.cloneDeep(params.lslConfig.query);

      $api.request(params)
      .then(res => {
        this.items = this.flatten(res.tradingFlowList, 'linkageInfo');
        this.displayItems = this.flatten(res.tradingFlowList, 'linkageInfo');

        if (!_.isEmpty(res.ownerSectionLists)) {
          this.$store.commit('SET_OWNER_SECTION_LISTS', _.map(res.ownerSectionLists, s => {
            return {
              ...s,
              label: s.sectionShortName,
              value: s.sectionId,
            }
          }));
        }
        this.totalCount = res.totalCount;
        this.isInitialSearch = this.lastQuery.initialFlag;
      })
      .catch(err => {
        if (err.isCanceled) {
          return;
        }
        if (err.statusCode === 403) {
          this.forbidden = true;
        }
        this.items = [];
        this.isInitialSearch = this.lastQuery.initialFlag;
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    search() {
      if (this.existValidationError) return;
      this.offset = 0;
      this.$store.commit('SET_QUERIES', {key: 'TRADING_LIST', queries: _.cloneDeep(this.searchForm)});
      this.fetch();
    },
    clear() {
      this.searchForm = clearSearchForm(this.searchForm);
      this.searchForm.flowUpdateDatetime = [
        dayjs().add(-this.selectableUpdateDateRange + 1, 'day').format('YYYY-MM-DD 00:00:00'),
        dayjs().format('YYYY-MM-DD 23:59:59')
      ];
      this.$store.commit('SET_QUERIES', {key: 'TRADING_LIST', queries: _.cloneDeep(this.searchForm)});
    },
    // 新規取引追加ドロワー表示
    addTrading() {
      // if (!this.addTradingResponse) {
      //   this.addTradingResponse = addTradingResponse;
      // }
      this.$store.commit('SET_DRAWER', true);
    },
    // タブ切り替え
    changeTab(tabIndex) {
      this.activeTab = tabIndex;
      this.$router.push({name: TABS[tabIndex]});
    },
    // ドロワーを閉じるときの確認
    closeConfirm() {
      this.$store.dispatch('SHOW_CONFIRM', 'Are you sure to go back? (Unsaved data shall be deleted)')
      .then(() => {
        this.$store.commit('SET_DRAWER', false);
      })
      .catch(() => {});
    },
    // 指定したオブジェクトの中身を一階層上に展開
    flatten(ary, target) {
      return _.map(ary, o => {
        return {..._.omit(o, [target]), ...o.linkageInfo};
      });
    },
    rowClick(row) {
      if (row.tradingSubmitStatusCd === TRADING_REGISTRATION_STATUS.SAVING && row.editableFlg === EDITABLE_FLG.ON) {
        this.$router.push({
          name: 'SavedTrading',
          params: { tradingId: row.tradingId },
        });
      } else {
        this.$router.push({
          name: 'ManageTrading',
          params: { tradingId: row.tradingId },
          query: { expand: row.tradingFlowId }
        });
      }
    },
    rowDbClick(row) {
      if (row.tradingSubmitStatusCd === TRADING_REGISTRATION_STATUS.SAVING && row.editableFlg === EDITABLE_FLG.ON) {
        this.linkToOtherWindow({
          name: 'SavedTrading',
          params: { tradingId: row.tradingId },
        });
      } else {
        this.linkToOtherWindow({
          name: 'ManageTrading',
          params: { tradingId: row.tradingId },
          query: { expand: row.tradingFlowId }
        });
      }
    },
    onPaging({target, sortType, currentPage}) {
      if (target && sortType) {
        const key = _.get(_.find(this.schemas, {key: target}), 'sortTarget');
        this.sort = {
          target: [TRADING_SEARCH_SORT_TARGET[key] || target],
          sortType: [sortType],
        };
      } else {
        this.sort = null;
      }

      this.offset = currentPage * this.limit - this.limit;
      const queries = {
        ...this.lastQuery,
        limit: this.limit,
        offset: this.offset,
        sort: this.sort,
      };
      this.fetch(queries);
    },
    async fetchIteration(query) {
      // bff_td_1 取引一覧検索BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/tradings/search',
          query: query
        }
      };
      try {
        return await $api.request(params);
      } catch (err) {
        throw err;
      }
    },
    // TSVダウンロード用に全件取得します
    fetchAll() {
      return new Promise(async (resolve, reject) => {
        let totalCount = 0;
        let items = [];
        const limit = 200;
        const query = { ...this.transformQueries(), offset: 0, limit: 0 };
        const key = 'TRADING_LIST';
        const id = new Date().getTime().toString();
        this.$store.dispatch('SET_DOWNLOAD_STATUS', { key, downloadStatus: { id, message: '0%' }});
        try {
          const res = await this.fetchIteration(query);
          totalCount = res.totalCount;
          while (items.length < totalCount) {
            // 0%の時は1件にする。それ以外は進捗率を表示
            const message = `${Math.min(Math.floor((items.length / totalCount) * 100), 100)}%`;
            this.$store.dispatch('SET_DOWNLOAD_STATUS', { key, downloadStatus: { id, message }});
            const res = await this.fetchIteration({ ...query, limit });
            items = items.concat(this.flatten(res.tradingFlowList, 'linkageInfo'));
            query.offset += limit;
          }
          this.$store.dispatch('SET_DOWNLOAD_STATUS', { key, downloadStatus: { id, message: '' }});
          resolve(items);
        } catch (err) {
          this.$store.dispatch('SET_DOWNLOAD_STATUS', { key, downloadStatus: { id, message: '' }});
          this.$store.dispatch('SHOW_ALERT', err.message);
          reject();
        }
      });
    },
    pickUpdateDate(dates) {
      if (dates.maxDate) {
        // minFlowUpdateDatetimeの更新はsetUpdateInfo内で実施（shortcutでもリセットさせる必要があるため）
        this.setUpdateDateInfo('reset');
      } else {
        this.minFlowUpdateDatetime = dates.minDate;
        this.setUpdateDateInfo();
      }
    },
    // 最終更新日時選択カレンダー内に必要なNODEが存在しない場合にのみ作成する
    createDivsForUpdateDate() {
      for(const className of ['selectable-date-range', 'selected-date']) {
        const targetEl = document.querySelector(`.${className}`)
        if(targetEl) continue;
        const newEl = document.createElement('div');
        newEl.className = className;
        const footerEl = document.querySelector('.el-picker-panel__footer');
        footerEl?.prepend(newEl);
      }
    },
    setUpdateDateInfo(type = 'update') {
      if(!['update', 'reset'].includes(type)) return;
      this.createDivsForUpdateDate();
      const dateLimitEl = document.querySelector('.selectable-date-range');
      const selectedDateEl = document.querySelector('.selected-date')
      let messageForSelectableDates = '';
      let messageForSelectedDate = '';
      
      if(type === 'update') {
        const minSelectableDate = dayjs(this.minFlowUpdateDatetime).add(-this.selectableUpdateDateRange + 1, 'day').format('YYYY-MM-DD');
        const maxSelectableDate = dayjs(this.minFlowUpdateDatetime).add(this.selectableUpdateDateRange - 1, 'day').format('YYYY-MM-DD');
        messageForSelectableDates = this.isEngLish ? `You can select from ${minSelectableDate} to ${maxSelectableDate}` : `${minSelectableDate}から${maxSelectableDate}まで選択できます`;

        const selectedDate = dayjs(this.minFlowUpdateDatetime).format('YYYY-MM-DD');
        messageForSelectedDate = this.isEngLish ? `${selectedDate} is selected` : `${selectedDate}が選択されています`
      } else this.minFlowUpdateDatetime = null;
      dateLimitEl.innerText = messageForSelectableDates;
      selectedDateEl.innerText = messageForSelectedDate;  
    },
    disabledUpdateDate(date) {
      if (this.minFlowUpdateDatetime) {
        return this.checkSystemMasterDaysOver(this.minFlowUpdateDatetime, date);
      }
      return false;
    },
    checkSystemMasterDaysOver(date1 = this.searchForm.flowUpdateDatetime?.[1], date2 = this.searchForm.flowUpdateDatetime?.[0]) {
      return Math.abs(dayjs(date1).diff(dayjs(date2), 'day')) > this.selectableUpdateDateRange - 1;
    }
  },
};
</script>

<style lang="scss" scoped>
  .tw_container {
      top: 113px;
  }
  .trading_tabs {
    display: flex;
    padding-top: 11px;
    padding-left: 3px;

    .tab {
      width: 70px;
      height: 34px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color_gray_800;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;

      &.active, &.disabled {
        pointer-events: none;
      }

      i {
        display: block;
        width: 24px;
        height: 24px;
        margin-right: 4px;
        background: no-repeat center;
        background-size: 20px auto;
      }

      &.list {
        margin-right: 51px;

        i {
          background-image: url(../assets/images/icons/icon_tab_list.svg);
        }

        &.active i {
          background-image: url(../assets/images/icons/icon_tab_active_list.svg);
        }
      }

      &.flow {
        margin-right: 47px;

        i {
          background-size: 24px auto;
          background-image: url(../assets/images/icons/icon_tab_flow.svg);
        }

        &.active i {
          background-image: url(../assets/images/icons/icon_tab_active_flow.svg);
        }
      }

      &.active {
        color: $color_dark_blue;
      }

      &.active:after {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3px;
        border-radius: 4px 4px 0px 0px;
        background: $color_dark_blue;
        content: '';
      }
    }
  }
  ::v-deep .tw_table {
    background-color: #d6d6dc;
    
    &.el-table--border th, &.el-table--border td {
      border-right: 1px solid #BFBFCD;
    }
    & .el-table__body, & .el-table__header {     
      min-width: unset !important;
      & .gutter {
        border: none;
        background-color: #d6d6dc;
      }
    }
  }

  ::v-deep #add_trading.side_drawer {
    // top: 66px;
    width: 850px;

    .inner {
      padding: 20px 44px 48px;
    }
  }

  .search_unit {
    & .el-form-item {
      margin-bottom: 24px
    }
    & .max-content {
      width: max-content;
    }
  }
</style>

<style lang="scss">
  .el-date-range-picker.within-system-trading-list-days {
    .el-picker-panel__footer {
      // clearableがfalseでもボタンが残るのでCSSで非表示とする（開発者ツールで表示可能だが、バリデーションでひっかかるようにしている）
      & .el-button.el-picker-panel__link-btn.el-button--text.el-button--mini {
        display: none;
      }

      & .selectable-date-range, & .selected-date {
        padding-left: 4px; 
        text-align: left;
        font-size: 14px;
      }
    }
  }
</style>
